import AnnouncementRoutes from "./announcements";
import BillingRoutes from "./billing";
import Cancellation from "./cancellation";
import CloudServers from "./cloudServers";
import Colocations from "./colocations";
import { constants } from "@/utils";
import DedicatedServers from "./dedicatedServers";
import DesignServices from "./designServices";
import DnsRoutes from "./dns";
import DomainRoutes from "./domains";
import EnterpriseCloud from "./enterpriseCloud";
import { hasPermissions } from "@/utils/usePermissions";
import Other from "./other";
import PartnerRoutes from "./partner";
import PlanRoutes from "./plans";
import ResellerHosting from "./resellerHosting";
import SettingsRoutes from "./settings";
import SharedHosting from "./sharedHosting";
import SslRoutes from "./ssl";
import SupportRoutes from "./support";

export default [
  {
    path: "",
    name: constants.ROUTES.HOME,
    component: () => import(/* webpackChunkName: "home" */ "@/pages/Dashboard/Home.vue"),
    meta: {
      breadcrumb: null,
    },
  },
  {
    path: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/pages/Dashboard/Profile.vue"),
    meta: {
      sidebar: "PROFILE",
    },
    children: [
      {
        path: "",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/pages/Dashboard/Profile/MyInfo.vue"),
        meta: {
          breadcrumb: "PROFILE_INFO",
        },
      },
      {
        path: "2fa",
        name: "2fa",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/pages/Dashboard/Profile/TwoFactorAuth.vue"),
        meta: {
          breadcrumb: "PROFILE_2FA",
        },
      },
    ],
  },
  {
    path: "announcements",
    component: () =>
      import(/* webpackChunkName: "announcements" */ "@/pages/Dashboard/Announcements.vue"),
    meta: {
      breadcrumb: "Announcements",
      sidebar: "ANNOUNCEMENTS",
    },
    children: AnnouncementRoutes,
  },
  {
    path: "billing",
    component: () => import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing.vue"),
    name: "billing",
    redirect: {
      name: "invoices",
    },
    meta: {
      breadcrumb: "BILLING",
      sidebar: "BILLING",
    },
    children: BillingRoutes,
    beforeEnter: hasPermissions.bind(null, {
      permissions: [
        "invoices",
        "credits",
        "addresses",
        "creditcards",
        "account",
        "payments",
        "orders",
      ],
      redirect: constants.ROUTES.HOME,
    }),
  },
  {
    path: "cancellation",
    component: () =>
      import(/* webpackChunkName: "cancellation" */ "@/pages/Dashboard/Cancellation.vue"),
    meta: {
      breadcrumb: "Cancellation",
    },
    redirect: {
      name: "request-cancellation",
    },
    children: Cancellation,
  },
  {
    path: "cloud-servers",
    component: () =>
      import(/* webpackChunkName: "cloud-servers" */ "@/pages/Dashboard/CloudServers.vue"),
    meta: {
      breadcrumb: "Cloud Servers",
    },
    redirect: {
      name: "cloud-servers",
    },
    children: CloudServers,
  },
  {
    path: "colocations",
    component: () =>
      import(/* webpackChunkName: "colocations" */ "@/pages/Dashboard/Colocations.vue"),
    meta: {
      breadcrumb: "Colocations",
    },
    children: Colocations,
  },
  {
    path: "design-services",
    component: () =>
      import(/* webpackChunkName: "design-services" */ "@/pages/Dashboard/DesignServices.vue"),
    children: DesignServices,
  },
  {
    path: "enterprise-cloud",
    component: () =>
      import(/* webpackChunkName: "enterprise-cloud" */ "@/pages/Dashboard/EnterpriseCloud.vue"),
    meta: {
      breadcrumb: "Enterprise Cloud",
    },
    redirect: {
      name: "enterprise-cloud",
    },
    children: EnterpriseCloud,
  },
  {
    path: "partner",
    name: "partner",
    component: () => import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner.vue"),
    meta: {
      breadcrumb: "PARTNER",
      sidebar: "PARTNER",
    },
    redirect: {
      name: "partner-links",
    },
    children: PartnerRoutes,
  },
  {
    path: "dns",
    name: "dns",
    redirect: {
      name: "dns-zones",
    },
    component: () => import(/* webpackChunkName: "dns" */ "@/pages/Dashboard/Dns.vue"),
    meta: {
      sidebar: "DNS",
    },
    children: DnsRoutes,
  },
  {
    path: "plans",
    name: "plans",
    component: () => import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans.vue"),
    redirect: {
      name: "plan-list",
    },
    children: PlanRoutes,
  },
  {
    path: "shared-hosting",
    component: () =>
      import(/* webpackChunkName: "shared-hosting" */ "@/pages/Dashboard/SharedHosting.vue"),
    children: SharedHosting,
  },
  {
    path: "dedicated-servers",
    redirect: {
      name: "dedicated-servers-list",
    },
    component: () =>
      import(/* webpackChunkName: "dedicated-servers" */ "@/pages/Dashboard/DedicatedServers.vue"),
    children: DedicatedServers,
  },
  {
    path: "domains",
    component: () => import(/* webpackChunkName: "domains" */ "@/pages/Dashboard/Domains.vue"),
    children: DomainRoutes,
  },
  {
    path: "reseller-hosting",
    component: () =>
      import(/* webpackChunkName: "reseller-hosting" */ "@/pages/Dashboard/ResellerHosting.vue"),
    children: ResellerHosting,
  },
  {
    path: "other",
    component: () => import(/* webpackChunkName: "other" */ "@/pages/Dashboard/Other.vue"),
    children: Other,
  },
  {
    path: "settings",
    name: "settings",
    redirect: {
      name: "team",
    },
    meta: {
      sidebar: "SETTINGS",
    },
    component: () => import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings.vue"),
    children: SettingsRoutes,
  },
  {
    path: "ssl",
    name: "ssl",
    redirect: {
      name: "certificates",
    },
    meta: {
      breadcrumb: "SSL",
    },
    component: () => import(/* webpackChunkName: "ssl" */ "@/pages/Dashboard/Ssl.vue"),
    children: SslRoutes,
  },
  {
    path: "support",
    name: "support",
    redirect: {
      name: "cases",
    },
    component: () => import(/* webpackChunkName: "support" */ "@/pages/Dashboard/Support.vue"),
    children: SupportRoutes,
  },
  {
    path: "feedback",
    name: "feedback",
    meta: {
      breadcrumb: "FEEDBACK",
    },
    component: () => import(/* webpackChunkName: "feedback" */ "@/pages/Dashboard/Feedback.vue"),
  },
  {
    path: "sites",
    name: "all-sites",
    meta: {
      breadcrumb: "SITES",
    },
    component: () => import(/* webpackChunkName: "other" */ "@/pages/Dashboard/Sites.vue"),
  },
  {
    path: "feedback:pathMatch(.*)",
    name: "feedback-board",
    meta: {
      breadcrumb: "FEEDBACK",
    },
    component: () => import(/* webpackChunkName: "feedback" */ "@/pages/Dashboard/Feedback.vue"),
    props: (route) => ({
      boardId: route.params.pathMatch,
    }),
  },
  {
    path: "/verify-case",
    name: constants.ROUTES.VERIFY_CASE,
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/Dashboard/VerifyTicket.vue"),
    props: (route) => ({
      id: route.query.id,
      clientId: Number(route.query.client),
    }),
    meta: {
      sidebar: "VERIFY-CASE",
      requiresAuth: true,
    },
  },
];
