import router from "@/router";
import store from "@/store";

const handler = (event) => {
  // The frontend gets a lot of invoice WS events for various reasons.
  // This condition slows down the fire hose.
  if (!router.currentRoute.value.name.includes("invoice")) {
    return;
  }
  switch (event) {
    case "invoice:add":
      store.dispatch("invoices/refreshList");
      break;
    case "invoice:update":
      store.dispatch("invoices/refreshList");
      break;
  }
};

export default handler;
